import { Event, day, month, dayYear, EventLevel } from '~/src/calendar/helpers.ts';

export default [
    // --- months ---
    new Event('{https://queerhistory.de=Queer History Month} (Monat der queeren Geschichte) in Deutschland und Kuba', 'Progress Pride', 5, month, EventLevel.Month),

    // --- static date ---
    new Event(
        'Jahrestag der Ehe für alle in Belgien',
        '_hrc',
        6,
        day(1),
        EventLevel.Day,
        [],
        null,
        null,
        (year) => year >= 2003,
    ),
    new Event(
        'Jahrestag der Ehe für alle in Luxemburg',
        '_hrc',
        1,
        day(1),
        EventLevel.Day,
        [],
        null,
        null,
        (year) => year >= 2015,
    ),
    new Event(
        'Jahrestag der Ehe für alle in Deutschland',
        '_hrc',
        10,
        day(1),
        EventLevel.Day,
        [],
        null,
        null,
        (year) => year >= 2017,
    ),
    new Event(
        'Jahrestag der dritten Option im Personenstandsgesetz in Deutschland',
        { type: 'flag', name: '_law', class: 'invertible' },
        12,
        day(22),
        EventLevel.Day,
        [],
        null,
        null,
        (year) => year >= 2018,
    ),
    new Event(
        'Jahrestag der Ehe für alle in Österreich',
        '_hrc',
        1,
        day(1),
        EventLevel.Day,
        [],
        null,
        null,
        (year) => year >= 2019,
    ),
    new Event(
        'Jahrestag der Ehe für alle in der Schweiz',
        '_hrc',
        7,
        day(1),
        EventLevel.Day,
        [],
        null,
        null,
        (year) => year >= 2022,
    ),
    new Event(
        'Selbstbestimmungsgesetz in Deutschland',
        { type: 'flag', name: '_law', class: 'invertible' },
        11,
        day(1),
        EventLevel.Day,
        [],
        null,
        null,
        (year) => year >= 2024,
    ),
    new Event(
        'Ehenamen- und Geburtsnamensrechtreform in Deutschland',
        { type: 'flag', name: '_law', class: 'invertible' },
        5,
        day(1),
        EventLevel.Day,
        [],
        null,
        null,
        (year) => year >= 2025,
    ),

    // --- one-off events ---
    new Event('{https://www.csd-d.de/de/=CSD Düsseldorf}', 'LGBTQ', 10, dayYear(16, 2021), EventLevel.Day),
    new Event('{https://csd-bielefeld.de/=CSD Bielefeld}', 'LGBTQ', 10, dayYear(2, 2021), EventLevel.Day),
    new Event('{https://www.facebook.com/events/133873485389727=CSD Erfurt}', 'LGBTQ', 10, dayYear(9, 2021), EventLevel.Day),
    new Event('{https://queereprovinz.de/home/=Queeres Leben statt nationalem Einheitsbrei}, Salzwedel', 'Progress Pride', 10, dayYear(3, 2021), EventLevel.Day),
    new Event(
        '{http://csd-potsdam.de/index.php/home=CSD Potsdam}',
        'Progress Pride_',
        5,
        dayYear(11, 2024),
        EventLevel.Day,
    ),
    new Event(
        '{https://csd-berlin.de/=CSD Berlin}',
        'Progress Pride_',
        7,
        dayYear(27, 2024),
        EventLevel.Day,
    ),
];
